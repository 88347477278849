import logo from "./logo.svg";
import "./App.css";
import emailjs from "@emailjs/browser";
import { useContext, useRef, useState } from "react";

import {
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Divider,
  Drawer,
  FloatButton,
  Form,
  Input,
  Modal,
  Popover,
  Result,
  Row,
  Segmented,
  Space,
  Steps,
  notification,
  theme,
} from "antd";
import {
  ContainerOutlined,
  FireOutlined,
  InstagramOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import axios from "./api/axios";
import RegisterModal from "./RegisterModal";
import { MainContext } from "./App";
import CardSection from "./CardSection/CardSection";
import dumbbell from "./images/icone/dumbbell.png";
import bilancia from "./images/icone/bilancia.png";
import corsa from "./images/icone/corsa.png";
import waist from "./images/icone/waist.png";
import melacarota from "./images/icone/melacarota.png";
import muscle from "./images/icone/muscle.png";
import volume from "./images/SezioneTecnica/volume.png";
import intensita from "./images/SezioneTecnica/Intensita.jpg";
import valori from "./images/SezioneTecnica/valoritrim.png";
import allenamento from "./images/SezioneTecnica/allenamento.png";
import maggInfo from "./images/percorso.png";
import tabella from "./images/SezioneTecnica/tabella.png";
import { useNavigate } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";
const { TextArea } = Input;

export const ContactUs = () => {
  const form = useRef();
};
const steps = [
  {
    title: "First",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];

function Home() {
  const { datiUtente, setDatiUtente } = useContext(MainContext);
  const windowWidth = window.screen.width;
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modal2Open, setModal2Open] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [percorsoOpen, setPercorsoOpen] = useState(false);
  const [tabAttiva, setTabAttiva] = useState("Percorso");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [altezza, setAltezza] = useState("");
  const [eta, setEta] = useState("");
  const [sesso, setSesso] = useState("");
  const [peso, setPeso] = useState("");
  const [obiettivo, setObiettivo] = useState("");
  const [livelloAttivita, setLivelloAttivita] = useState("");
  const [infortuni, setInfortuni] = useState("");
  const [doveAlleni, setDoveAlleni] = useState("");
  const [tempoDisposizione, setTempoDisposizione] = useState("");
  const [giorniSettimana, setGiorniSettimana] = useState("");
  // const [nome, setNome] = useState("");
  const navigate = useNavigate();
  function scrollToPricing() {
    const element = document.getElementById("pricing");
    //you can do it by jquery. no matter
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
  function cleanFormValues() {
    let nome = (document.getElementById("nome").value = "");
    const cognome = (document.getElementById("cognome").value = "");
    var eta = (document.getElementById("eta").value = "");
    var sesso = (document.getElementById("sesso").value = "");
    var altezza = (document.getElementById("altezza").value = "");
    var peso = (document.getElementById("peso").value = "");
    var obiettivo = (document.getElementById("obiettivo").value = "");
    var email = (document.getElementById("email").value = "");
    var livello = (document.getElementById("livello").value = "");
    var infortuniOproblemi = (document.getElementById(
      "infortuniOproblemi"
    ).value = "");
    var doveAlleni = (document.getElementById("doveAlleni").value = "");
    var tempoDisposizione = (document.getElementById(
      "tempoDisposizione"
    ).value = "");
    var giorniSettimana = (document.getElementById("giorniSettimana").value =
      "");
  }
  const tabs = [
    { title: "Tab 1", content: <div>Tab 1 Content</div> },
    { title: "Tab 2", content: <div>Tab 2 Content</div> },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  function saveData() {
    const datiScheda = {
      nome: nome,
      cognome: cognome,
      eta: eta,
      sesso: sesso,
      altezza: altezza,
      peso: peso,
      obiettivo: obiettivo,
      email: email,
      livelloAttivita: livelloAttivita,
      infortuni: infortuni,
      doveAlleni: doveAlleni,
      tempoDisposizione: tempoDisposizione,
      giorniSettimana: giorniSettimana,
    };
    localStorage.setItem("datiScheda", JSON.stringify(datiScheda));
  }

  async function login() {
    if (email === "" || password === "") {
      api.info({
        message: `Inserire email e password`,
      });
    } else {
      await axios
        .get(`api/Main/${email}/${password}`)
        .then((res) => {
          let data = res.data;
          console.log("data", data);
          let foundUser = data.email;
          if (foundUser === email) {
            localStorage.setItem("utente", JSON.stringify(data));
            setDatiUtente(data);
            console.log("dati utente isinde login", data);
            navigate("/utente");
            api.success({
              message: `Login effettuato con successo`,
            });
          } else {
            api.info({
              message: `${data}`,
            });
          }
        })
        .catch((error) =>
          api.error({
            message: `${error}`,
          })
        );
    }
  }
  const onChange = (currentSlide) => {};
  const [open, setOpen] = useState(false);
  const [openSchedaModal, setOpenSchedaModal] = useState(false);
  const [pagamentoSelezionato, setPagamentoSelezionato] = useState("");
  console.log("pagamentoSelezionato", pagamentoSelezionato);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handleOpenChangeScheda = (newOpen) => {
    setOpenSchedaModal(newOpen);
  };
  const dumbbellSvg = (color) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      class='icon icon-tabler icon-tabler-barbell'
      width={color === "#fff" ? "30" : "30"}
      height={color === "#fff" ? "30" : "30"}
      viewBox={color === "#fff" ? "1.5 6 20 20" : "2 1.5 20 20"}
      stroke-width='1'
      stroke={color}
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M2 12h1' />
      <path d='M6 8h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2' />
      <path d='M6 7v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-10a1 1 0 0 0 -1 -1h-1a1 1 0 0 0 -1 1z' />
      <path d='M9 12h6' />
      <path d='M15 7v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-10a1 1 0 0 0 -1 -1h-1a1 1 0 0 0 -1 1z' />
      <path d='M18 8h2a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-2' />
      <path d='M22 12h-1' />
    </svg>
  );
  const DumbbellIcon = ({ color }) => (
    <Icon component={() => dumbbellSvg(color)} />
  );

  return (
    <div className='App'>
      <Modal
        open={open}
        onCancel={() => setOpen(!open)}
        onOpenChange={handleOpenChange}
        centered
        footer={() => {}}
        closable={true}
        style={{ border: "solid 0px white" }}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          border: "solid 0px white",
        }}
      >
        <Card className='cardFloadButton'>
          {/* <Card
              style={{ width: "300px" }}
            > */}
          <strong style={{ fontSize: "16px" }}>
            Ti stai allenando ma non sei sicuro del tuo programma?
          </strong>
          <Divider style={{ margin: "5px 0px" }}></Divider>
          <br />
          <Row align={"top"}>
            <Col span={6}>
              <div class='about-iconFloatButton'>
                {" "}
                <DumbbellIcon color={"#fff"} />
              </div>
            </Col>
            <Col span={17}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Richiedi un'analisi della tua scheda
              </span>
            </Col>
          </Row>
          <Row justify={"center"}>
            <div>
              {/* <img src='assets/img/icon/price.svg' alt='' /> */}

              <div class='single-features d-flex'>
                <div class='features-icon mr-2'>
                  <img src='assets/img/icon/check.svg' alt='' />
                </div>
                <div class='features-caption'>
                  <p>Informazioni su cosa modificare</p>
                </div>
              </div>
              <div class='single-features d-flex'>
                <div class='features-icon mr-2'>
                  <img src='assets/img/icon/check.svg' alt='' />
                </div>
                <div class='features-caption'>
                  <p>Come impostare l'allenamento</p>
                </div>
              </div>
              <div class='single-features d-flex'>
                <div class='features-icon mr-2'>
                  <img src='assets/img/icon/check.svg' alt='' />
                </div>
                <div class='features-caption mb-5'>
                  <p>Come fare le progressioni</p>
                </div>
              </div>

              <div class='properties__caption'>
                <a
                  style={{ marginLeft: "22px", border: "solid 2px #fd6f01" }}
                  href='https://buy.stripe.com/fZe16b8Jab7j4s8145'
                  class='border-btn border-btn2'
                >
                  richiedi €4,99
                </a>
                <a className='border-bt1 hero-btn'>
                  <span></span>
                </a>
              </div>
            </div>
          </Row>
          {/* </Card> */}

          {/* <a onClick={hide}>Close</a> */}
        </Card>
      </Modal>
      {/* <Popover
        trigger='click'
        open={open}
        onOpenChange={handleOpenChange}
        placement='left'
        // content={

        // }
        title={
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
            }}
          >
            Analisi scheda
          </span>
        }
      >
         </Popover> */}
      <div className='floatBtn'>
        <div className='floatbuttonWhatsapp'>
          <a href='https://wa.me/393500990138?' target='_blank'>
            <WhatsAppOutlined
              size={50}
              style={{ fontSize: "25px", color: "white" }}
            ></WhatsAppOutlined>
          </a>
        </div>
      </div>
      <div className='floatBtn'>
        <div className='floatbuttonDumbbell' onClick={handleOpenChange}>
          <DumbbellIcon
            style={{ fontSize: "25px", color: "black" }}
            color='black'
          />
        </div>
      </div>
      {/* <FloatButton
        type='default'
        className='floatbutton'
        style={{ width: "50px" }}
        tooltip="Richiedi un'analisi della tua scheda"
        icon={
          <DumbbellIcon color='black' />
    
        }
        onClick={handleOpenChange}
      /> */}

      {contextHolder}
      <RegisterModal
        open={openRegisterModal}
        setOpen={setOpenRegisterModal}
        setModal2Open={setModal2Open}
      ></RegisterModal>
      <Drawer
        style={{
          borderRadius: "10px",
        }}
        title='Maggiori dettagli percorso'
        placement={windowWidth > 550 ? "left" : "bottom"}
        onClose={() => setPercorsoOpen(false)}
        open={percorsoOpen}
      >
        <img
          src={maggInfo}
          style={{ objectFit: "contain", width: "100%" }}
          alt='maggiori info dettagli percorso'
        ></img>
      </Drawer>

      <div className='black-bg'>
        {/* <div id='preloader-active'>
          <div className='preloader d-flex align-items-center justify-content-center'>
            <div className='preloader-inner position-relative'>
              <div className='preloader-circle'></div>
              <div className='preloader-img pere-text'>
                <img src='assets/img/logo/loderMihai.png' alt='' />
              </div>
            </div>
          </div>
        </div> */}
        <header>
          <div className='header-area header-transparent'>
            <div
              className='main-header header-sticky'
              // style={{ background: "grey" }}
            >
              <div className='container-fluid'>
                <div
                  className='
                menu-wrapper
                d-flex
                align-items-center
                justify-content-between
              '
                >
                  <div className='logo'>
                    {/* <a href='index.html'>
                      <img src='assets/img/logo/logo.png' alt='' />
                    </a> */}
                    <h4 style={{ color: "white", fontSize: "22px" }}>
                      MIHAI ANGHELUS
                    </h4>
                  </div>
                  <span></span>

                  <div className='main-menu f-right d-none d-lg-block'>
                    <nav>
                      <ul id='navigation'>
                        {/* <li>
                          <a href='index.html' style={{ fontSize: "22px" }}>
                            Home
                          </a>
                        </li> */}
                        {/* <li>
                          <a href='contact.html' style={{ fontSize: "22px" }}>
                            About
                          </a>
                        </li>

                        <li>
                          <a href='contact.html' style={{ fontSize: "22px" }}>
                            Contatti
                          </a>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Space direction='horizontal'>
                      <div
                        className='header-btns d-lg-block f-right'
                        onClick={() => setOpenSchedaModal(true)}
                      >
                        <a
                          href='#'
                          className='btn btnRichiediScheda'
                          // data-toggle='modal'
                          // data-target='#exampleModal'
                        >
                          Richiedi scheda
                        </a>
                      </div>
                      {/* <Button
                        onClick={() => setModal2Open(true)}
                        style={{ background: "transparent", color: "white" }}
                      >
                        Accedi
                      </Button>
                      <Button
                        onClick={() => setOpenRegisterModal(true)}
                        type='primary'
                      >
                        Registrati
                      </Button> */}
                    </Space>
                  </div>

                  <div className='col-12'>
                    <div className='mobile_menu d-block d-lg-none'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div className='slider-area position-relative'>
            <div className='slider-active'>
              <div
                className='single-slider slider-height d-flex align-items-center'
                style={{ height: "100vh" }}
              >
                <div className='container'>
                  <div className='row'>
                    <div
                      className='col-xl-12 col-lg-12 col-md-12'
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        className='hero__caption mb-25'
                        style={{ textAlign: "center" }}
                      >
                        <span
                          data-animation='fadeInLeft '
                          className='titles marginBott mb-25 mt-150 gradient1'
                          // className='mb-25'
                          data-delay='0.1s'
                        >
                          Sviluppa il potenziale del tuo fisico
                        </span>

                        <h2
                          data-animation='fadeInLeft'
                          className='mb-25 mobFont  '
                          style={{ fontSize: "30px", color: "white" }}
                          data-delay='0.4s'
                        >
                          {/* I primi passi iniziano qui, compila il questionario e
                          richiedi subito la tua scheda personalizzata */}
                          Allenati con ardore e spingiti alla conquista dei tuoi
                          limiti
                        </h2>
                        <div
                          href=''
                          className='bordergradient border-btn1 hero-btn mb-65'
                          data-animation='fadeInLeft'
                          data-delay='0.8s'
                          // data-toggle='modal'
                          // data-target='#exampleModal'
                          onClick={scrollToPricing}
                        >
                          Inizia
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            // title="Accedi all'area personale"
            // centered
            open={modal2Open}
            onOk={() => setModal2Open(false)}
            onCancel={() => setModal2Open(false)}
            footer={[""]}
            width={"350px"}
          >
            <Row justify='center' style={{ marginBottom: "20px" }}>
              <h4>Accedi all'area personale</h4>
            </Row>
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                name='Email'
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='Email'
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  placeholder='Password'
                />
              </Form.Item>
              <Form.Item>
                {/* <Form.Item name='remember' valuePropName='checked' noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                <a
                  className='login-form-forgot'
                  style={{ color: "blue" }}
                  href=''
                >
                  Password dimenticata
                </a>
              </Form.Item>
              <Row justify='center'>
                <Space justifyContent='center'>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='login-form-button'
                      onClick={() => login()}
                    >
                      Log in
                    </Button>
                    Or{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      href=''
                      onClick={() => {
                        setModal2Open(false);
                        setOpenRegisterModal(true);
                      }}
                    >
                      register now!
                    </span>
                  </Form.Item>
                </Space>
              </Row>
            </Form>
          </Modal>

          <section
            className='team-area fix '
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div
              className='d-flex align-items-center '
              style={{ backgroundColor: "white" }}
            >
              <div className='col-12 px-1'>
                <h4 className='freesection1 mb-0 obliquo'>
                  <div
                    className='header-btns  '

                    // onClick={() => setOpenSchedaModal(true)}
                  >
                    <span style={{ color: "#f3f3f3" }} className='pr-4'>
                      Scheda massa muscolare{" "}
                    </span>{" "}
                    <a
                      href='https://buy.stripe.com/3cs2afbVm4IV7EkfZ1'
                      className='btn btnRichiediSchedaFast '
                      style={{ color: "black" }}
                      // data-toggle='modal'
                      // data-target='#exampleModal'
                    >
                      Richiedi
                    </a>
                  </div>
                </h4>
              </div>

              {/* <button className='header-btns'>scheda massa</button> */}
              {/* <div
                className='header-btns  f-right'
                onClick={() => setOpenSchedaModal(true)}
              >
                <a
                  href='#'
                  className='btn btnRichiediScheda'
                  style={{ color: "black" }}
                  // data-toggle='modal'
                  // data-target='#exampleModal'
                >
                  Richiedi
                </a>
              </div> */}
            </div>
            {/* <Divider></Divider> */}

            <div className='container pt-4 '>
              <div className='row'>
                <div className='col-xl-12'>
                  <div
                    className='section-tittle text-center wow fadeInUp'
                    data-wow-duration='1s'
                    data-wow-delay='.1s'
                    style={{ marginBottom: "-15px", marginTop: "-5px" }}
                  >
                    <h3
                      className='titles titlesmobile'
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "36px",
                      }}
                    >
                      BENVENUTO NEL MONDO DELL'ALLENAMENTO
                    </h3>
                  </div>
                </div>
              </div>

              <div className='footer-area1 mt-40 '>
                <div className='container mb-25'>
                  <div
                    className='footer-top '
                    style={{ display: "block", justifyContent: "start" }}
                  >
                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                            data-wow-delay='.8s'
                          >
                            <a href='#'>
                              <div className='fixheightIcons'></div>
                              {/* <i
                                style={{ fontSize: "35px" }}
                                className='fab fa-twitter'
                              ></i> */}
                              <img
                                className='imgIcons'
                                src={muscle}
                                alt='muscle'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=' texticonSection col-10' style={{}}>
                        Massa
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            data-wow-delay='.8s'
                          >
                            <a href='https://bit.ly/sai4ull'>
                              <div className='fixheightIcons'></div>
                              <img
                                className='imgIcons'
                                src={dumbbell}
                                alt='dumbbell'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=' texticonSection col-10' style={{}}>
                        Forza
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            data-wow-delay='.8s'
                          >
                            <a href='#"'>
                              <div className='fixheightIcons'></div>

                              <img
                                className='imgIcons'
                                src={waist}
                                alt='waist'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=' texticonSection col-10' style={{}}>
                        Tonificazione
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            data-wow-delay='.8s'
                          >
                            <a href='#'>
                              {/* <i
                                style={{ fontSize: "35px" }}
                                className='fab fa-pinterest-p'
                              ></i> */}
                              <div className='fixheightIcons'></div>
                              <img
                                src={bilancia}
                                className='imgIcons'
                                alt='bilancia'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=' texticonSection col-10' style={{}}>
                        Perdere peso
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            data-wow-delay='.8s'
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <a href='#'>
                              <div className='fixheightIcons'></div>
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                              </div> */}
                              <img
                                className='imgIcons'
                                src={corsa}
                                alt='corsa'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className=' texticonSection col-10'
                        style={{
                          alignItems: "center",
                        }}
                      >
                        Ricomposizione corporea
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <div className='single-footer-caption text-center'>
                          <div
                            className='footer-social  wow fadeInUp'
                            data-wow-duration='3s'
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            data-wow-delay='.8s'
                          >
                            <a href='#'>
                              {/* <i
                                style={{ fontSize: "35px" }}
                                className='fab fa-pinterest-p'
                              ></i> */}
                              <div className='fixheightIcons'></div>
                              <img
                                className='imgIcons'
                                src={melacarota}
                                alt='melacarota'
                              ></img>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=' texticonSection col-10'>
                        Benessere generale
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <section className='pricing-area section-padding40 fix'>
                <div className='container'>
                  <div className='row'></div>
                  <div className='row justify-content-center'>
                    <div className='col-lg-6 col-md-8 col-sm-8'>
                      <div
                        className='properties mb-30 wow fadeInUp '
                        data-wow-duration='1s'
                        data-wow-delay='.4s'
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className='about-icon'>
                            <img
                              className='about-iconImage'
                              src='assets/img/icon/price.svg'
                              alt=''
                            />
                          </div>
                          <p className='mb-25 mt-25 priceMobSize'>
                            €30/m <span></span>
                          </p>
                        </div>
                        <div
                          className='properties__card'
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div className='properties__caption'>
                            <div className='single-features'>
                              <div className='features-icon'>
                                <img src='assets/img/icon/check.svg' alt='' />
                              </div>
                              <div className='features-caption  featureCaptionResizeMob'>
                                <p className='featureCaptionResizeMob'>
                                  Scheda personalizzata
                                </p>
                              </div>
                            </div>
                            <div className='single-features'>
                              <div className='features-icon'>
                                <img src='assets/img/icon/check.svg' alt='' />
                              </div>
                              <div className='features-caption featureCaptionResizeMob'>
                                <p className='featureCaptionResizeMob'>
                                  Metodo per le progressioni
                                </p>
                              </div>
                            </div>
                            <div className='single-features'>
                              <div className='features-icon'>
                                <img src='assets/img/icon/check.svg' alt='' />
                              </div>
                              <div className='features-caption featureCaptionResizeMob'>
                                <p className='featureCaptionResizeMob'>
                                  Assistenza e correzione esercizi
                                </p>
                              </div>
                            </div>
                            <div className='single-features'>
                              <div className='features-icon'>
                                <img src='assets/img/icon/check.svg' alt='' />
                              </div>
                              <div className='features-caption featureCaptionResizeMob'>
                                <p className='featureCaptionResizeMob'>
                                  Consigli alimentari
                                </p>
                              </div>
                            </div>
                            <div className='single-features'>
                              <div className='features-icon'>
                                <img src='assets/img/icon/check.svg' alt='' />
                              </div>
                              <div className='features-caption featureCaptionResizeMob'>
                                <p className='featureCaptionResizeMob'>
                                  Controllo periodico
                                </p>
                              </div>
                            </div>

                            <a
                              href='#'
                              className='border-btn border-btn2'
                              data-toggle='modal'
                              data-target='#exampleModal'
                            >
                              Richiedi programma
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
          </section>
          <section
            className='team-area fix pt-50 grey-bg'
            style={{ backgroundColor: "black" }}
          >
            <div className='container '>
              <div className='row'>
                <div className='col-xl-12'>
                  <div
                    className='section-tittle text-center mb-25 wow fadeInUp'
                    data-wow-duration='1s'
                    data-wow-delay='.1s'
                  >
                    <h3
                      className='titles titlesmobile'
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "36px",
                      }}
                    >
                      Il tuo obiettivo richiede la combinazione di questi
                      fattori:
                      {/* QUI TROVERAI TUTTO QUELLO DI CUI HAI BISOGNO PER
                      RAGGIUNGERE I RISULTATI CHE RICERCHI */}
                    </h3>
                    <div
                      style={{
                        marginTop: "40px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CardSection></CardSection>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </section>
          <section
            className='team-area fix pt-50 grey-bg'
            style={{ backgroundColor: "white" }}
          >
            <div className='container '>
              <div className='row'>
                <div className='col-xl-12'>
                  <div
                    className=' text-center mb-25 wow fadeInUp'
                    data-wow-duration='1s'
                    data-wow-delay='.1s'
                  >
                    <h3
                      className='titles titlesmobile'
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "36px",
                        paddingBottom: "1rem",
                      }}
                    >
                      Variabili da considerare in un allenamento
                    </h3>
                    <div style={{ width: "98%" }}>
                      <p
                        // style={{
                        //   lineHeight: 1.2,
                        //   color: "black",
                        //   textAlign: "start",
                        // }}
                        className='subtitlesmobile'
                        style={{
                          lineHeight: 1.2,
                          textAlign: "start",
                        }}
                      >
                        Ogni volta che ti stai allenando ci sono dei{" "}
                        <span style={{ color: "#fd6f01" }}>parametri </span>
                        che indicano il tuo andamento e la qualità
                        dell'allenamento!
                      </p>
                      <p
                        className='subtitlesmobile'
                        // style={{
                        //   lineHeight: 1.2,
                        //   color: "black",
                        //   width: "100%",
                        //   textAlign: "start",
                        // }}
                        style={{ lineHeight: 1.2, textAlign: "start" }}
                      >
                        Saperli{" "}
                        <span style={{ color: "#fd6f01" }}>gestire </span> ti
                        permette di progredire nel corso del tempo!
                      </p>
                    </div>
                    <div
                      className=' mb-25 wow fadeInUp'
                      data-wow-duration='1s'
                      data-wow-delay='.1s'
                      style={{ lineHeight: 1.2, textAlign: "start" }}
                    >
                      <p
                        className='subtitlesdescription'
                        style={{ lineHeight: 1.2, textAlign: "start" }}
                      >
                        <span style={{ color: "#fd6f01" }}>Intensità: </span> o
                        intensità di carico è un parametro dell'allenamento che
                        viene espresso in percentuale rispetto al propio 1RM, ed
                        è una delle variabili di misura del
                        sovraccarico progressivo.
                      </p>
                      <p className='subtitlesdescription'>
                        <span style={{ color: "#fd6f01" }}>Volume: </span>
                        la quantità di lavoro svolto durante la seduta di
                        allenamento o durante un mesociclo, solitamente misurato
                        in serie allenanti.
                      </p>
                      <p className='subtitlesdescription'>
                        <span style={{ color: "#fd6f01" }}> T.U.T: </span>
                        Time Under Tension ovvero tempo di esecuzione di
                        un esercizio.
                      </p>
                      <p className='subtitlesdescription'>
                        <span style={{ color: "#fd6f01" }}> Densità: </span>è la
                        quantità di lavoro eseguita in un determinato intervallo
                        di tempo, tra cui il recupero fra le serie.
                      </p>
                    </div>
                    <Carousel
                      autoplay
                      autoplaySpeed={5000}
                      dots={true}
                      dotPosition='bottom'
                      afterChange={onChange}
                      style={{}}
                    >
                      <div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            className='widthcarouseltablet'
                            src={valori}
                            alt='descrizione valori'
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            className='widthcarouseltablet'
                            src={volume}
                            alt='volume'
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            className='widthcarouseltablet'
                            src={tabella}
                            alt='tabella'
                          />
                        </div>
                      </div>
                    </Carousel>

                    {/* <h4>Intensità: aumento del carico</h4>
                    <h4>Volume: aumento delle serie e/o ripetizion</h4>
                    <h4>Densità: tempo di recupero fra le serie</h4>
                    <h4>
                      T.U.T: time under tension, tempo in qui il muscolo è sotto
                      tensione
                    </h4>
                    <h5>
                      Il nostro corpo si adatta all'allenamento aumentando la
                      massa muscolare e la forza
                    </h5> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div className='row mt-80 mb-80'>
              <div className='col-lg-3 col-md-6'>
                <div
                  className='single-cat text-center mb-30 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.2s'
                >
                  <div className='cat-icon'>
                    <img
                      style={{ borderRadius: "1rem", width: "99%" }}
                      src='assets/img/gallery/bagInHandsmall.jpg'
                      alt=''
                    />
                  </div>
                  <div className='cat-cap'>
                    <h5>
                      <a href='services.html'>1. Scheda personalizzata</a>
                    </h5>
                    <p>
                      La scheda viene creata in base agli obiettivi che vuoi
                      raggiungere e al tuo livello di allenamento atttuale,
                      qualsiasi esso sia. Si inseriscono esercizi per
                      incrementare parametri come la forza e stimolare la
                      crescita della massa muscolare.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div
                  className='single-cat text-center mb-30 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.6s'
                >
                  <div className='cat-icon'>
                    <img
                      style={{ bordeRradius: "1rem", width: "99%" }}
                      src='assets/img/gallery/bagInHandsmall.jpg'
                      alt=''
                    />
                  </div>
                  <div className='cat-cap'>
                    <h5>
                      <a href='services.html'>2. Controllo periodico</a>
                    </h5>
                    <p>
                      Guarderemo insieme l'andamento dei tuoi allenamenti e le
                      modifiche da fare in base alle tue esigenze{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div
                  className='single-cat text-center mb-30 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.4s'
                >
                  <div className='cat-icon'>
                    <img
                      style={{ bordeRradius: "1rem", width: "99%" }}
                      src='assets/img/gallery/progressionsmall.jpg'
                      alt=''
                    />
                  </div>
                  <div className='cat-cap'>
                    <h5>
                      <a href='services.html'>3. Metodo per le progressioni</a>
                    </h5>
                    <p>
                      Avere un metodo ti permetterà di migliorare le tue
                      prestazioni. seguendo le percentuali di carico e di
                      intensità potrai ottenere risultati a ogni allenamento.
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6'>
                <div
                  className='single-cat text-center mb-30 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.6s'
                >
                  <div className='cat-icon'>
                    <img
                      style={{ bordeRradius: "1rem", width: "99%" }}
                      src='assets/img/gallery/standingDumbbelssmall.jpg'
                      alt=''
                    />
                  </div>
                  <div className='cat-cap'>
                    <h5>
                      <a href='services.html'>
                        4. Assistenza e correzione esercizi
                      </a>
                    </h5>
                    <p>
                      Andremo a perfezionare la tecnica di esecuzione degli
                      esercizi e il modo in cui deve essere fatta la scheda.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          <section
            class='pricing-area section-padding40 fix grey-bg pt-40'
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <h5
              className='titles titlesmobile'
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "36px",
                paddingBottom: "1rem",
              }}
            >
              Monitorare l'allenamento
            </h5>
            <p
              className='subtitlesmobile sezmonitorareAllenamento'
              style={
                {
                  // paddingBottom: "2rem",
                  // padding: "0rem 1.4rem 1rem 1.4rem",
                  // lineHeight: 1.2,
                  // textAlign: "start",
                }
              }
            >
              È molto importante monitorare e cambiare le{" "}
              <span style={{ color: "#fd6f01" }}>variabili allenanti</span> in
              modo da dare uno stimolo progressivo nel tempo.
            </p>
            <p className='subtitlesmobile sezmonitorareAllenamento'>
              Il{" "}
              <span style={{ color: "#fd6f01" }}> diario di allenamento</span>{" "}
              ti aiuterà a tenere traccia dei tuoi progressi e di rispettare il
              programma di allenamento.
            </p>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{
                    padding: "15px 15px 0px 15px",
                    margin: "5px 0px 0px 14px",
                  }}
                  className='widthcarouseltablet'
                  src={allenamento}
                  alt='descrizione valori'
                />
              </div>
            </div>
            <Divider></Divider>
            <div style={{ backgroundColor: "lightgray" }}>
              <h5 className='freesection'>
                Analizziamo i tuoi feedback e insieme gestiamo{" "}
                <span style={{ color: "#f3f3f3" }}>
                  le variabili settimanalmente{" "}
                </span>{" "}
                in base ai tuoi miglioramenti
              </h5>
            </div>

            <Divider></Divider>
          </section>

          <section
            class='pricing-area section-padding40 fix grey-bg'
            style={{ backgroundColor: "lightgray" }}
          >
            <div className='row'>
              <div className='col-xl-12'>
                <div
                  className='section-tittle text-center mb-25 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.1s'
                >
                  <h3
                    className='titles mt-35 '
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "30px",
                    }}
                  >
                    Scegli un percorso
                    {/* {tabAttiva === "Percorso"
                    ? "Scegli un percorso"
                      : "Scegli scheda"} */}
                  </h3>
                  {/* <h2 className='titles'>Percorsi</h2> */}
                </div>
              </div>
            </div>

            <div class='container' id='pricing'>
              <Segmented
                size='large'
                style={{
                  marginBottom: "20px",
                  padding: "2px 2px",
                  // backgroundColor: "white",
                }}
                options={["Percorso", "Scheda"]}
                value={tabAttiva}
                onChange={setTabAttiva}
              />

              <div>
                {tabAttiva === "Scheda" ? (
                  <div
                  // style={{
                  //   position: "relative",
                  //   width: "500px",
                  //   margin: "0 auto",
                  // }}
                  >
                    <div
                      id='slideScheda'
                      class='carousel slide'
                      data-ride='carousel'
                      data-interval='false'
                    >
                      <ol class='carousel-indicators'>
                        <li
                          data-target='#slideScheda'
                          data-slide-to='0'
                          class='active'
                        ></li>
                        <li data-target='#slideScheda' data-slide-to='1'></li>
                      </ol>
                      <div class='carousel-inner'>
                        <div class='carousel-item active  '>
                          <div class='col-lg-4 col-md-6 col-sm-6 '>
                            <div
                              class='properties mb-30 wow '
                              data-wow-duration='1s'
                              data-wow-delay='.2s'
                            >
                              <Card bodyStyle={{ padding: "0px" }}>
                                <div class='properties__card'>
                                  <div class='about-icon'>
                                    <img
                                      src='assets/img/icon/price.svg'
                                      alt=''
                                    />
                                  </div>
                                  <div class='properties__caption'>
                                    <span class='month'>
                                      Scheda personalizzata
                                    </span>
                                    <p class='mb-15 mt-10'>
                                      €49 <span>/a scheda</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Scheda personalizzata</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Consigli sull'allenamento</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/x.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>
                                              Assistenza e correzione esercizi
                                            </p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/x.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Controllo periodico</p>
                                          </div>
                                        </div>
                                        <div class='single-features mb-20'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/x.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Metodo progressioni</p>
                                          </div>
                                        </div>
                                        <a
                                          href='#'
                                          class='border-btn border-btn2'
                                          onClick={() =>
                                            setOpenSchedaModal(true)
                                          }
                                        >
                                          Inizia ora
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </div>
                        <div class='carousel-item '>
                          <div class='col-lg-4 col-md-6 col-sm-6 '>
                            <div
                              class='properties mb-30 wow '
                              data-wow-duration='1s'
                              data-wow-delay='.6s'
                            >
                              <Card bodyStyle={{ padding: "0px" }}>
                                <div class='properties__card'>
                                  <div class='about-icon'>
                                    <img
                                      src='assets/img/icon/price.svg'
                                      alt=''
                                    />
                                  </div>
                                  <div class='properties__caption'>
                                    <span class='month'>
                                      il mio allenamento
                                    </span>
                                    <p class='mb-15 mt-10'>
                                      €89 <span>/2 settimane</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Accesso alla mia scheda </p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Dati e progressioni</p>
                                          </div>
                                        </div>
                                        <div class='single-features '>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Overview ogni allenamento</p>
                                          </div>
                                        </div>
                                        <div class='single-features mb-20'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption '>
                                            <p>Accesso diario d'allenamento</p>
                                          </div>
                                        </div>
                                        {/* <div class='single-features mb-10'>
                                        <div class='features-icon'>
                                          <img
                                            src='assets/img/icon/check.svg'
                                            alt=''
                                          />
                                        </div>
                                        <div class='features-caption'>
                                          <p>Month to mouth</p>
                                        </div>
                                      </div> */}
                                        <a
                                          href='https://buy.stripe.com/3cs16b3oQ8Zb4s8cMO'
                                          class='border-btn border-btn2'
                                        >
                                          Inizia ora
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        class='carousel-control-prev'
                        href='#slideScheda'
                        role='button'
                        data-slide='prev'
                      >
                        {/* <span
                          class='carousel-control-prev-icon'
                          aria-hidden='true'
                        ></span> */}
                        <i
                          class='fa fa-chevron-left'
                          aria-hidden='true'
                          style={{ fontSize: "44px", color: "black" }}
                        ></i>

                        <span class='sr-only'>Previous</span>
                      </a>
                      <a
                        class='carousel-control-next'
                        href='#slideScheda'
                        role='button'
                        data-slide='next'
                      >
                        {/* <span
                          class='carousel-control-next-icon'
                          aria-hidden='true'
                        ></span> */}
                        {/* <i className='fas fa-level-up-alt' ></i> */}
                        <i
                          class='fa fa-chevron-right'
                          aria-hidden='true'
                          style={{ fontSize: "44px", color: "black" }}
                        ></i>
                        <span class='sr-only'>Next</span>
                      </a>
                    </div>
                    {/* <Carousel
                      dots={true}
                      dotPosition='bottom'
                      // afterChange={onChange}
                      // style={{}}
                      arrows
                      infinite={false}
                      // prevArrow={customPrevArrow}
                      // nextArrow={customNextArrow}
                      className='arrowstyle'
                    >
                      
                      
                    </Carousel> */}
                  </div>
                ) : null}
                {tabAttiva === "Percorso" ? (
                  <>
                    <div
                      id='slidePercorso'
                      class='carousel slide'
                      data-ride='carousel'
                      data-interval='false'
                    >
                      <ol class='carousel-indicators'>
                        <li
                          data-target='#slidePercorso'
                          data-slide-to='0'
                          class='active'
                        ></li>
                        <li data-target='#slidePercorso' data-slide-to='1'></li>
                        <li data-target='#slidePercorso' data-slide-to='2'></li>
                      </ol>
                      <div class='carousel-inner'>
                        <div class='carousel-item active  '>
                          <div class='col-lg-4 col-md-6 col-sm-6'>
                            <div
                              class='properties mb-30 wow '
                              data-wow-duration='1s'
                              data-wow-delay='.4s'
                            >
                              <Card
                                bodyStyle={{
                                  padding: "0px",
                                }}
                              >
                                <div class='properties__card'>
                                  <div class='about-icon'>
                                    <img
                                      src='assets/img/icon/price.svg'
                                      alt=''
                                    />
                                  </div>
                                  <div class='properties__caption'>
                                    <span class='month'>
                                      Percorso personalizzato
                                      {/* <br /> 1 mese */}
                                    </span>
                                    <p class='mb-15 mt-10'>
                                      €69 <span>/1 mese</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Scheda personalizzata</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Metodo per le progressioni</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>
                                              Assistenza e correzione esercizi
                                            </p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Diario di allenamento</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Consigli alimentari</p>
                                          </div>
                                        </div>
                                        <div class='single-features mb-10'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Controllo periodico</p>
                                          </div>
                                        </div>
                                        <a
                                          href='https://buy.stripe.com/5kAcOTf7y4IV1fWcMQ'
                                          class='border-btn border-btn2'
                                        >
                                          Inizia ora
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              <Row justify={"center"}>
                                <div
                                  style={{
                                    marginTop: "-10px",
                                    border: "solid 1px #ffd7b9",
                                    padding: "4px 8px",
                                    borderRadius: "12px",
                                    color: "#ffd7b9",
                                    backgroundColor: "#fd6f01",
                                    zIndex: 5,
                                    fontWeight: "800",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPercorsoOpen(true);
                                  }}
                                >
                                  maggiori info
                                </div>
                              </Row>
                            </div>
                          </div>
                        </div>
                        <div class='carousel-item '>
                          <div class='col-lg-4 col-md-6 col-sm-6'>
                            <div
                              class='properties mb-30 wow '
                              data-wow-duration='1s'
                              data-wow-delay='.4s'
                            >
                              <Card
                                bodyStyle={{
                                  padding: "0px",
                                }}
                              >
                                <div class='properties__card'>
                                  <div class='about-icon'>
                                    <img
                                      src='assets/img/icon/price.svg'
                                      alt=''
                                    />
                                  </div>
                                  <div class='properties__caption'>
                                    <span class='month'>
                                      Percorso personalizzato
                                      {/* <br /> 1 mese */}
                                    </span>
                                    <p class='mb-15 mt-10'>
                                      €177 <span>/3 mesi</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Scheda personalizzata</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Metodo per le progressioni</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>
                                              Assistenza e correzione esercizi
                                            </p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Diario di allenamento</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Consigli alimentari</p>
                                          </div>
                                        </div>
                                        <div class='single-features mb-10'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Controllo periodico</p>
                                          </div>
                                        </div>
                                        <a
                                          href='https://buy.stripe.com/8wMeX1gbC4IVf6M9AF'
                                          class='border-btn border-btn2'
                                        >
                                          Inizia ora
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              <Row justify={"center"}>
                                <div
                                  style={{
                                    marginTop: "-10px",
                                    border: "solid 1px #ffd7b9",
                                    padding: "4px 8px",
                                    borderRadius: "12px",
                                    color: "#ffd7b9",
                                    backgroundColor: "#fd6f01",
                                    zIndex: 5,
                                    fontWeight: "800",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPercorsoOpen(true);
                                  }}
                                >
                                  maggiori info
                                </div>
                              </Row>
                            </div>
                          </div>
                        </div>
                        <div class='carousel-item '>
                          <div class='col-lg-4 col-md-6 col-sm-6'>
                            <div
                              class='properties mb-30 wow '
                              data-wow-duration='1s'
                              data-wow-delay='.4s'
                            >
                              <Card
                                bodyStyle={{
                                  padding: "0px",
                                }}
                              >
                                <div class='properties__card'>
                                  <div class='about-icon'>
                                    <img
                                      src='assets/img/icon/price.svg'
                                      alt=''
                                    />
                                  </div>
                                  <div class='properties__caption'>
                                    <span class='month'>
                                      Percorso personalizzato
                                      {/* <br /> 1 mese */}
                                    </span>
                                    <p class='mb-15 mt-10'>
                                      €324 <span>/6 mesi</span>
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Scheda personalizzata</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Metodo per le progressioni</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>
                                              Assistenza e correzione esercizi
                                            </p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p>Diario di allenamento</p>
                                          </div>
                                        </div>
                                        <div class='single-features'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Consigli alimentari</p>
                                          </div>
                                        </div>
                                        <div class='single-features mb-10'>
                                          <div class='features-icon'>
                                            <img
                                              src='assets/img/icon/check.svg'
                                              alt=''
                                            />
                                          </div>
                                          <div class='features-caption'>
                                            <p> Controllo periodico</p>
                                          </div>
                                        </div>
                                        <a
                                          href='https://buy.stripe.com/00g027e3u5MZbUA144'
                                          class='border-btn border-btn2'
                                        >
                                          Inizia ora
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                              <Row justify={"center"}>
                                <div
                                  style={{
                                    marginTop: "-10px",
                                    border: "solid 1px #ffd7b9",
                                    padding: "4px 8px",
                                    borderRadius: "12px",
                                    color: "#ffd7b9",
                                    backgroundColor: "#fd6f01",
                                    zIndex: 5,
                                    fontWeight: "800",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPercorsoOpen(true);
                                  }}
                                >
                                  maggiori info
                                </div>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        class='carousel-control-prev'
                        href='#slidePercorso'
                        role='button'
                        data-slide='prev'
                      >
                        {/* <span
                          class='carousel-control-prev-icon'
                          aria-hidden='true'
                        ></span> */}
                        <i
                          class='fa fa-chevron-left'
                          aria-hidden='true'
                          style={{ fontSize: "44px", color: "black" }}
                        ></i>

                        <span class='sr-only'>Previous</span>
                      </a>
                      <a
                        class='carousel-control-next'
                        href='#slidePercorso'
                        role='button'
                        data-slide='next'
                      >
                        {/* <span
                          class='carousel-control-next-icon'
                          aria-hidden='true'
                        ></span> */}
                        {/* <i className='fas fa-level-up-alt' ></i> */}
                        <i
                          class='fa fa-chevron-right'
                          aria-hidden='true'
                          style={{ fontSize: "44px", color: "black" }}
                        ></i>
                        <span class='sr-only'>Next</span>
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </section>
          <section className='about-area2 fix pb-padding pt-50 pb-80 bg-grey'>
            <div className='support-wrapper align-items-center'>
              <div className='right-content2'>
                <div
                  className='right-img wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.1s'
                >
                  <img src='assets/img/gallery/finishRefinished.jpg' alt='' />
                </div>
              </div>
              <div className='left-content2'>
                <div
                  className='section-tittle2 mb-20 wow fadeInUp'
                  data-wow-duration='1s'
                  data-wow-delay='.3s'
                >
                  <div className='front-text'>
                    <h2 className=''>Chi Sono</h2>
                    <p style={{ textAlign: "start" }}>
                      Dedicato all'allenamento come stile di vita, attualmente
                      sono istruttore e personal trainer in palestra. Ho
                      iniziato questo percorso nel mondo del fitness diversi
                      anni fa, il mio intento è quello di aiutare le persone a
                      sviluppare il proprio potenziale migliorando la loro
                      condizione fisica e mentale perché l'allenamento diventi
                      una parte immancabile nella loro vita e porti un benessere
                      generale in ognuno di noi.
                    </p>
                    <p className='mb-40' style={{ textAlign: "start" }}>
                      Insieme valuteremo le condizioni fisiche e lo stato
                      dell'attività fisica attuale, imposteremo gli obiettivi da
                      raggiungere e il metodo di allenamento. Ti preparo una
                      scheda di allenamento e ti fornisco le istruzioni su come
                      eseguirla, dandoti dei metodi per le progressioni,
                      assistenza, correzione degli esercizi e consigli
                      alimentari. Periodicamente verifichiamo l'andamento
                      dell'allenamento ed eventuali modifiche nel programma.
                    </p>
                    {/* <a
                      href='#'
                      className='border-btn'
                      data-toggle='modal'
                      data-target='#exampleModal'
                    >
                      Richiedi programma
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div
            className='video-area section-bg2 d-flex align-items-center'
            data-background='assets/img/gallery/video-bg.png'
          >
            <div className='container'>
              <div className='video-wrap position-relative'>
                <div className='video-icon'>
                  <a
                    className='popup-video btn-icon'
                    href='https://www.youtube.com/watch?v=up68UAfH0d0'
                  >
                    <i className='fas fa-play'></i>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <section className='services-area'>
            <div className='container'>
              <div className='row justify-content-between'>
                <div className='col-xl-4 col-lg-4 col-md4 col-sm-8'>
                  <div
                    className='single-services wow fadeInUp'
                    data-wow-duration='1s'
                    data-wow-delay='.1s'
                  >
                    <a
                      href='https://instagram.com/mihai.los.anghelus?igshid=NGExMmI2YTkyZg=='
                      target='_blank'
                    >
                      <div className='features-icon'>
                        <InstagramOutlined
                          style={{
                            color: "white",
                            fontSize: "35px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        ></InstagramOutlined>

                        {/* <img
                          src='assets/img/icon/icons8-instagram-48.svg'
                          alt=''
                        /> */}
                      </div>
                    </a>
                    <a
                      href='https://instagram.com/mihai.los.anghelus?igshid=NGExMmI2YTkyZg=='
                      target='_blank'
                    >
                      <div className='features-caption'>
                        <h3>Instagram</h3>
                        <p>300+ followers</p>
                      </div>
                    </a>
                  </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md4 col-sm-8'>
                  <div
                    className='single-services wow fadeInUp'
                    data-wow-duration='2s'
                    data-wow-delay='.4s'
                  >
                    <a
                      href=''
                      data-delay='0.8s'
                      data-toggle='modal'
                      data-target='#exampleModal'
                    >
                      <div className='features-icon'>
                        <img src='assets/img/icon/icons8-email-48.png' alt='' />
                      </div>
                    </a>
                    <div className='features-caption'>
                      <h3>Email</h3>
                      <p style={{ marginLeft: "2px" }}>Invia un'email</p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md4 col-sm-8'>
                  <div
                    className='single-services wow fadeInUp'
                    data-wow-duration='2s'
                    data-wow-delay='.4s'
                  >
                    <a href='https://wa.me/393500990138?' target='_blank'>
                      <div className='features-icon'>
                        <WhatsAppOutlined
                          style={{
                            color: "#25D366",
                            fontSize: "35px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        ></WhatsAppOutlined>
                      </div>
                    </a>
                    <a href='https://wa.me/393500990138?' target='_blank'>
                      <div className='features-caption'>
                        <h3>WhatsApp</h3>
                        <p>Inizia chat</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <div className='footer-area black-bg'>
            <div className='container'>
              <div className='footer-top footer-padding'>
                <div className='row'>
                  <div className='col-xl-12'>
                    <div className='single-footer-caption mb-50 text-center'>
                      {/* <div
                        className='footer-logo wow fadeInUp'
                        data-wow-duration='1s'
                        data-wow-delay='.2s'
                      >
                        <h4>MIHAI ANGHELUS</h4>
                        <h4>MIHAI ANGHELUS</h4>
                      </div> */}
                      <div
                        className='header-area main-header2 wow fadeInUp'
                        data-wow-duration='2s'
                        data-wow-delay='.4s'
                      >
                        <div className='main-header main-header2'>
                          <div className='menu-wrapper menu-wrapper2'>
                            <div className='main-menu main-menu2 text-center'>
                              <nav>
                                <ul>
                                  <li>
                                    <a href='/'>Home</a>
                                  </li>
                                  {/* <li>
                                    <a href='about.html'>About</a>
                                  </li>

                                  <li>
                                    <a href='contact.html'>Contatti</a>
                                  </li> */}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='footer-social mt-30 wow fadeInUp'
                        data-wow-duration='3s'
                        data-wow-delay='.8s'
                      >
                        <a href='#'>
                          <i className='fab fa-twitter'></i>
                        </a>
                        <a href='https://bit.ly/sai4ull'>
                          <i className='fab fa-facebook-f'></i>
                        </a>
                        <a href='#'>
                          <i className='fab fa-pinterest-p'></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div
          className='modal fade'
          id='exampleModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <Modal
            open={openSchedaModal}
            onCancel={() => setOpenSchedaModal(false)}
            onOpenChange={handleOpenChangeScheda}
            centered
            footer={[
              <>
                {current === 2 ? (
                  <Row justify={"space-between"}>
                    <Button type='default' onClick={() => prev()}>
                      Indietro
                    </Button>
                    <Button
                      className={
                        pagamentoSelezionato !== ""
                          ? "checkout"
                          : "bordercheckout"
                      }
                      style={{
                        // color: "white !important",

                        borderColor: "#fd6f01",
                        color:
                          pagamentoSelezionato === "" ? "#fd6f01" : "white",
                      }}
                      color='white'
                      type='secondary'
                      onClick={() => {
                        saveData();
                        if (pagamentoSelezionato !== "") {
                          window.location.replace(pagamentoSelezionato);
                        } else {
                          api.info({
                            message: `Seleziona un metodo di pagamento, la tua nuova scheda è già in arrivo`,
                          });
                        }
                      }}
                    >
                      Checkout
                    </Button>
                  </Row>
                ) : (
                  <Row justify={"space-between"}>
                    <Button type='default' onClick={() => prev()}>
                      Indietro
                    </Button>
                    <Button
                      type='primary'
                      className='checkout'
                      onClick={() => next()}
                    >
                      Successivo
                    </Button>
                  </Row>
                )}
              </>,
            ]}
            closable={true}
            style={{ border: "solid 0px white" }}
            bodyStyle={{
              display: "flex",
              justifyContent: "center",
              border: "solid 0px white",
            }}
          >
            <div>
              <div>
                <Row justify={"center"} className='mb-30'>
                  <h2 className='modal-title' id='exampleModalLabel'>
                    Ottieni una scheda presonalizzata ora
                  </h2>
                </Row>
                <div>
                  <form>
                    <div>
                      <Steps
                        labelPlacement='vertical'
                        responsive={false}
                        onChange={(e) => setCurrent(e)}
                        current={current}
                        items={[
                          {
                            title: <div>Contatto</div>,
                            content: (
                              <div style={{ width: "10px !important" }}>
                                hello
                              </div>
                            ),
                            // icon: <UserOutlined />,
                            icon: (
                              <SolutionOutlined
                                style={{
                                  fontSize: "30px",
                                  color: current >= 0 ? "#fd6f01" : "",
                                }}
                              />
                            ),
                          },
                          {
                            title: "Obiettivo",
                            content: "Second-content",
                            icon: (
                              <FireOutlined
                                style={{
                                  fontSize: "30px",
                                  color: current >= 1 ? "#fd6f01" : "",
                                }}
                              />
                            ),
                          },
                          // {
                          //   title: "Pagamento",
                          //   content: "Last-content",
                          //   icon: (
                          //     <ContainerOutlined style={{ fontSize: "30px" }} />
                          //   ),
                          // },
                          {
                            title: "Invio dati",
                            content: "Last-content",
                            icon: (
                              <SmileOutlined
                                style={{
                                  fontSize: "30px",
                                  color: current >= 2 ? "#fd6f01" : "",
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>

                    {current === 0 ? (
                      <div>
                        <div className='form-row mt-0 '>
                          <div className='form-group mt-20 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputEmail4'>
                              Nome
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control-lg'
                              id='nome'
                              placeholder='Nome'
                              onChange={(e) => setNome(e.target.value)}
                              value={nome}
                            ></Input>
                          </div>
                          <div className='form-group mt-20 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputPassword4'>
                              Cognome{" "}
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control-lg'
                              id='cognome'
                              placeholder='Cognome'
                              onChange={(e) => setCognome(e.target.value)}
                              value={cognome}
                            ></Input>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='form-group mt-20 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputEmail4'>
                              Età
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control-lg'
                              id='eta'
                              placeholder='Età'
                              onChange={(e) => setEta(e.target.value)}
                              value={eta}
                            ></Input>
                          </div>
                          <div className='form-group mt-20 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputPassword4'>
                              Sesso{" "}
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control-lg'
                              id='sesso'
                              placeholder='Sesso'
                              onChange={(e) => setSesso(e.target.value)}
                              value={sesso}
                            ></Input>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='form-group mt-20 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputEmail4'>
                              Altezza cm
                            </label>
                            <Input
                              type='number'
                              className='form-control form-control-lg'
                              id='altezza'
                              placeholder='Altezza cm'
                              onChange={(e) => setAltezza(e.target.value)}
                              value={altezza}
                            ></Input>
                          </div>

                          <div className='form-group mt-20 mb-30 form-control-lg  col-md-6 paddingMobile'>
                            <label className='strong' htmlFor='inputPassword4'>
                              Peso{" "}
                            </label>
                            <Input
                              type='number'
                              className='form-control form-control-lg'
                              id='peso'
                              placeholder='Peso'
                              onChange={(e) => setPeso(e.target.value)}
                              value={peso}
                            ></Input>
                          </div>
                        </div>
                        {/* <div className='form-row'>
                        <div className='form-group mt-20 form-control-lg  col-md-6'>
                          <Checkbox
                            onChange={(e) => {
                              // e.target.checked
                            }}
                          >
                            Registrati al sito
                          </Checkbox>
                        </div>
                      </div> */}
                      </div>
                    ) : null}
                    {current === 1 ? (
                      <>
                        <Col style={{ marginTop: "50px" }}>
                          <div className='mt-30 mb-30 form-group form-control-lg  col-md-6 paddingMobile'>
                            <label
                              className='strong'
                              htmlFor='exampleFormControlTextarea1 '
                            >
                              Obiettivo
                            </label>
                            <Input
                              placeholder={`Descrivi l'obiettivo che vuoi raggiungere`}
                              className='form-control form-control-lg'
                              id='obiettivo'
                              value={obiettivo}
                              onChange={(e) => setObiettivo(e.target.value)}
                            ></Input>
                            {/* <textarea
                          className='form-control form-control-lg'
                          id='obiettivo'
                          rows='3'
                        ></textarea> */}
                          </div>
                        </Col>

                        <Col style={{ marginTop: "1rem" }}>
                          <div className='mt-20 mb-30 form-group form-control-lg  col-md-6  paddingMobile '>
                            <label className='strong'>
                              Livello di attività attuale
                            </label>
                            <Input
                              placeholder='Livello di attività attuale (come ti sei allenato
                              fino ad oggi)'
                              className='form-control form-control-lg'
                              id='livello'
                              value={livelloAttivita}
                              onChange={(e) =>
                                setLivelloAttivita(e.target.value)
                              }
                            ></Input>
                          </div>
                        </Col>
                        <Col style={{ marginTop: "1rem" }}>
                          <div className='mt-20 mb-30 form-group form-control-lg  col-md-6  paddingMobile '>
                            <label className='strong' htmlFor=''>
                              Eventuali infortuni e /o problemi di salute
                            </label>
                            <Input
                              placeholder='Eventuali infortuni'
                              className='form-control form-control form-control-lg '
                              id='infortuniOproblemi'
                              value={infortuni}
                              onChange={(e) => setInfortuni(e.target.value)}
                            ></Input>
                          </div>
                        </Col>

                        <Col style={{ marginTop: "1rem" }}>
                          <div className=' mt-20 mb-30 form-group form-control-lg  col-md-6 paddingMobile '>
                            <label className='strong' htmlFor='dove ti alleni'>
                              Dove ti alleni (palestra/casa)
                            </label>

                            <Input
                              type='text'
                              className='form-control form-control form-control-lg '
                              id='doveAlleni'
                              placeholder='Dove ti alleni'
                              value={doveAlleni}
                              onChange={(e) => setDoveAlleni(e.target.value)}
                            ></Input>
                          </div>
                        </Col>

                        <Col style={{ marginTop: "1rem" }}>
                          <div className='mt-20 mb-30 form-group form-control-lg  col-md-6   paddingMobile'>
                            <label className='strong' htmlFor='dove ti alleni'>
                              Quanto tempo hai a disposizione
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control form-control-lg '
                              id='tempoDisposizione'
                              placeholder='Tempo a disposizione'
                              value={tempoDisposizione}
                              onChange={(e) =>
                                setTempoDisposizione(e.target.value)
                              }
                            ></Input>
                          </div>
                        </Col>

                        <Col style={{ marginTop: "1rem" }}>
                          <div className='mt-20 mb-30 form-group form-control-lg  col-md-6  paddingMobile '>
                            <label className='strong' htmlFor='dove ti alleni'>
                              Quanti giorni ti puoi allenare a settimana
                            </label>
                            <Input
                              type='text'
                              className='form-control form-control form-control-lg '
                              id='giorniSettimana'
                              placeholder='Giorni a settimana'
                              value={giorniSettimana}
                              onChange={(e) =>
                                setGiorniSettimana(e.target.value)
                              }
                            ></Input>
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {current === 2 ? (
                      <>
                        <Result
                          status='success'
                          style={{ fontSize: "10px", padding: "15px 10px" }}
                        ></Result>
                        <Row
                          justify={"center"}
                          style={{
                            fontSize: "16px",
                            marginTop: "-35px",
                            marginBottom: "15px",
                          }}
                        >
                          <Row>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {" "}
                              Ben fatto!
                            </span>{" "}
                          </Row>
                        </Row>
                        <div className='paddingMobile'>
                          <Row justify={"start"} style={{ fontSize: "16px" }}>
                            1. Scegli il metodo di pagamento
                          </Row>
                          <Row justify={"start"} style={{ fontSize: "16px" }}>
                            2. Fai il checkout
                          </Row>
                          <Row justify={"start"} style={{ fontSize: "16px" }}>
                            3. Nei prossimi due giorni riceverai la tua nuova
                            scheda!
                          </Row>
                        </div>

                        <Row justify={"space-evenly"} className='mt-20 mb-20'>
                          <Button
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontWeight: "900",
                              width: "30%",
                            }}
                            onClick={() =>
                              setPagamentoSelezionato(
                                "https://www.satispay.com/download/qrcode/S6Y-CON--7C0750ED-CC15-4216-BB73-B828130F0EE6"
                              )
                            }
                          >
                            Satispay
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "#ffd140",
                              color: "white",
                              fontWeight: "bold",
                              width: "30%",
                            }}
                            onClick={() =>
                              setPagamentoSelezionato(
                                "https://buy.stripe.com/3cs2afbVm4IV7EkfZ1"
                              )
                            }
                          >
                            <span
                              style={{
                                color: "#003087",
                                fontStyle: "italic",
                                fontWeight: "bolder",
                              }}
                            >
                              Pay{" "}
                            </span>{" "}
                            <span
                              style={{
                                color: "#0070e0",

                                fontStyle: "italic",
                                fontWeight: "bolder",
                              }}
                            >
                              Pal
                            </span>
                          </Button>
                          <Button
                            style={{
                              fontWeight: "bold",
                              width: "30%",
                            }}
                            onClick={() =>
                              setPagamentoSelezionato(
                                "https://buy.stripe.com/3cs2afbVm4IV7EkfZ1"
                              )
                            }
                          >
                            Carta
                          </Button>
                        </Row>
                      </>
                    ) : null}
                  </form>
                </div>
                {/* <div className='modal-footer mt-5'>
                  <div>
                    {
                     
                    }
                  </div>
                  </div> */}
                {/* <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Invia dati
                </button> */}
              </div>
            </div>
          </Modal>

          <div
            id='alert'
            className='alert alert-success alert-dismissible fade show w-50 h3'
            style={{ display: "none", position: "absolute", top: "0%" }}
            role='alert'
          >
            <strong>Holy guacamole!</strong> You should check in on some of
            those fields below.
            <button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times,</span>
            </button>
          </div>
        </div>
        <div id='back-top'>
          <a title='Go to Top' href='#'>
            {" "}
            <i className='fas fa-level-up-alt'></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
